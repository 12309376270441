import {useEffect, useState, useRef} from 'react';
import React from 'react';
import TrainingsplanCarousel from '../ui/TrainingsplanCarousel';
import LoadingSpinner from './LoadingSpinner';

const TrainingsplanList = (props) => {
    
    const [activeTrainingsplan, setActiveTrainingsplan] = useState([]);
    const [userName, setUserName] = useState([]); 
    
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
      setIsLoading(true);  
      fetch(
            process.env.REACT_APP_API_HOST+'/trainingsplan', //training/user
            {
              method: 'GET',
              headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                        'Access-Control-Request-Headers': 'Authorization',
                        //'Content-Type': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
            })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              const trainingsplan = [];

              for (const key in data) {
                const tmpPlan = {
                  id: key,
                  ...data[key]
                };
                
                trainingsplan.push(tmpPlan);
              }
              setActiveTrainingsplan(trainingsplan);  
              setIsLoading(false);               
            });

  },[]); 

  if (isLoading) {
    return (<LoadingSpinner />);
  }
  
  return (<TrainingsplanCarousel trainingsplan={activeTrainingsplan} />)

   }

  export default TrainingsplanList;