import { useState, useEffect, useContext } from 'react';
import React from 'react';
import WorkoutHistoryComponent from '../components/widgets/WorkoutHistoryComponent';
import WorkoutPumpForm from '../components/ui/WorkoutPumpForm';
import LoadingSpinner from '../components/widgets/LoadingSpinner';
import {format,  formatDistance,parse} from 'date-fns';
import Trainingspanel from '../components/ui/Trainingspanel';
import WorkoutExerciseRanking from '../components/widgets/WorkoutExcericeRanking';

function WorkoutPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTraining, setCurrentTraining] = useState('');
  const [queryWTPIData, setQueryWTPIData] = useState(0);
  const [newWorkout, setNewWorkout] = useState([]);

  const currentUserIdToRetrieve = JSON.parse(localStorage.getItem("pumpDB.user"));
  const currentPlanIdToRetrieve = JSON.parse(localStorage.getItem("pumpDB.plan"));

  const startTraining = 
      {
        user : currentUserIdToRetrieve.id,
        latitude : null,
        longitude : null,
        adress: null
      };
   
  function pushWeightHandler(pushWeightData){
        console.log("bekomme von unten: " + pushWeightData.name);
        console.log("bekomme von unten: " + pushWeightData.exercise);
        //setQueryWTPIData(pushWeightData);
        setNewWorkout(pushWeightData);
  }

  useEffect(() => {
    document.title = 'pump 3.0 - Life is Peachy -';

    setIsLoading(true);
    fetch(
      process.env.REACT_APP_API_HOST+'/training',
      {
        method: 'POST',
        body: JSON.stringify(startTraining),
        headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                  'Access-Control-Request-Headers': 'Authorization',
                   //'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded'
                 }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        for (const key in data) {
          const t = {
            id: key,
            ...data[key]
          };
          const parsedDate = parse(t.start, 'yyyy-MM-dd HH:mm:ss', new Date());
          t.start = format(parsedDate, 'dd.MM.yyyy HH:mm:ss')
          setCurrentTraining(t);
        }
        setIsLoading(false);
      })
      .catch(error => {console.log(error);  throw(error);} );
  }, []);

  if (isLoading) {
    return (<LoadingSpinner />);
  }

  return <><section >
            <h1>welcome to lift</h1>
            <div>
              <Trainingspanel training={currentTraining} tp={currentPlanIdToRetrieve}/>  
            </div>            
             <div>
                <p>your plan today</p>
                <WorkoutPumpForm componentName="pumpTP" trainingID={currentTraining.id} displayMode="TP" onPushWeight={pushWeightHandler} />
                <br />
                <WorkoutPumpForm componentName="pump" trainingID={currentTraining.id} displayMode="SELECT" onPushWeight={pushWeightHandler} />
             </div> 
             
             <div>
                <p>your ranking</p>
                <WorkoutExerciseRanking training={currentTraining.id} exercise={newWorkout.exercise} rankRefresh={newWorkout.id} />
             </div>
             <div>
                your improvements today
             </div>
             <div>
                <p>your exercises</p>
                <WorkoutHistoryComponent  training={currentTraining.id}
                                          queryWorkoutHistoryData={queryWTPIData}
                                          newWorkout={newWorkout}/>
             </div>
          </section>
          </>
}

export default WorkoutPage;