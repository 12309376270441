import React from 'react';
import { Route, Routes, Navigate  } from 'react-router-dom'
import Login from './pages/Login';
import Signup from './pages/Signup';
import Signout from './pages/Signout';
import Home from './pages/Home';
import ExercisesPage from './pages/Exercises';
import DevBoardPage from './pages/DevBoard';
import TrainingPage from './pages/Training';
import WorkoutPage from './pages/Workout';
import { useContext } from "react";
import { AuthContext } from "./store/AuthContext";
import Layout from './components/layout/Layout';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const {currentUser} = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };
           
    return ( 
    <Layout>
      
      <Routes>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path='/' exact element={<RequireAuth><Home/></RequireAuth>} /> 
        <Route path='/training' exact element={<RequireAuth><TrainingPage/></RequireAuth>} /> 
        <Route path='/workout' exact element={<RequireAuth><WorkoutPage/></RequireAuth>} /> 
        <Route path='/exercises' exact element={<RequireAuth><ExercisesPage/></RequireAuth>} /> 
        <Route path='/dev' exact element={<RequireAuth><DevBoardPage/></RequireAuth>} /> 
        <Route path='/out' element={<RequireAuth><Signout/></RequireAuth>} />
      </Routes>

    </Layout>
  );
}

export default App;
