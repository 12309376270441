import {useState, useEffect} from 'react';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

const WeightInput = (props) => {

const [weight, setWeight] = useState(props.selectedWeight);


useEffect(()=>{
  setWeight(props.selectedWeight);
},[props.selectedWeight]); 

const handleWeightChange = (e) => {
  e.preventDefault(); 
  const { name, value } = e.target;
  setWeight(value);

  //nach oben geben
  props.onChangeWeight(value);
};

const handleTPIpumpSubmit = (e) => {
  //verhindert Standardverhalten -> Absenden des Formulars
  e.preventDefault();  
  // Handle form submission, e.g., send data to a server
  props.onPushWeight(weight);
};
    

  return (<Stack direction="horizontal" gap={3}>
            <Form >
              <Form.Control size="lg" type="text" className="me-auto" placeholder="add your weight" value={weight} onChange={handleWeightChange}/>
            </Form>  
            <Button variant="secondary" onClick={handleTPIpumpSubmit}>Submit</Button>           
          </Stack>)
   }

  export default WeightInput;