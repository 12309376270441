import {useEffect, useState, useRef} from 'react';
import React from 'react';
import ExerciseList from '../ui/ExerciseList';

const WorkoutSelectComponent = (props) => {
    
    function changeExerciseHandler(exerciseData){
      // nach oben geben
      props.onExerciseChange(exerciseData);
    }
  
  return (<ExerciseList displayMode="SELECT" onExerciseChange={changeExerciseHandler} />)
   }

  export default WorkoutSelectComponent;