import React from 'react';
import {  signOut } from "firebase/auth";
import {auth} from '../firebase';
import { useNavigate } from 'react-router-dom';
import {useContext} from 'react';
import {AuthContext} from "../store/AuthContext";

import classes from './Login.module.css';
import Card from '../components/ui/Card';
 
const Signout = () => {
    const navigate = useNavigate();
    const {dispatch} = useContext(AuthContext);

    const handleLogout = () => {               
        signOut(auth).then(() => {
            // Sign-out successful.
            dispatch({type:"LOGOUT"})
            localStorage.setItem("APP_USER",null);
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
        // An error happened.
        });
    }
   
    return(
        <>
            <Card>
            <form className={classes.form}>                                              
                <div className={classes.control}> 
                Tschüß
                </div>     
                <div className={classes.actions}>
                                <button onClick={handleLogout}>Logout</button>
                            </div>   
                        </form>
            </Card>
        </>
    )
}
 
export default Signout;
