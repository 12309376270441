import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Training.module.css';
import WorkoutTPIComponent from '../components/widgets/WorkoutTPIComponent';
import TrainingsplanList from '../components/widgets/TrainingsplanList';
import UserAndPlanContext from '../store/UserAndPlanContext';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import InputGroup from 'react-bootstrap/InputGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {format,  formatDistance} from 'date-fns';
import WorkoutPumpForm from '../components/ui/WorkoutPumpForm';
import ExerciseList from '../components/ui/ExerciseList';
import DeleteButton from '../components/widgets/DeleteButton';

function ExercisesPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const userAndPlanCtx = useContext(UserAndPlanContext);
  const debugTime = format(new Date, "dd.MM.yyyy HH:mm:ss");
  
  const currentUserIdToRetrieve = JSON.parse(localStorage.getItem("pumpDB.user"));
  const currentPlanIdToRetrieve = JSON.parse(localStorage.getItem("pumpDB.plan"));

  const handleDeleteWorkout = (id) => {
    // Hier kannst du die Logik zum Löschen des Datensatzes implementieren
    console.log(`Datensatz mit ID ${id} wird gelöscht...`);
  };
  
  
  const [weight, setWeight] = useState(props.selectedWeight);
  const handleTPIpumpSubmit = (e) => {
    //verhindert Standardverhalten -> Absenden des Formulars
    e.preventDefault();
    console.log('klick btn');
    
    // Handle form submission, e.g., send data to a server
    console.log('props.onPushWeight(weight)');
  };
  const handleWeightChange = (e) => {
    const { name, value } = e.target;
    setWeight(value);
  
    //nach oben geben
    console.log('props.onChangeWeight(value)');
  };

  /* Reps*/
  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState('1');

  const radios = [
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
    { name: '6', value: '6' },
    { name: '7', value: '7' },
    { name: '8', value: '8' },
    { name: '9', value: '9' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
    { name: '13', value: '13' },
    { name: '14', value: '14' },
    { name: '15', value: '15' },
    { name: '16', value: '16' },
    { name: '17', value: '17' },
    { name: '18', value: '18' },
    { name: '19', value: '19' },
    { name: '20', value: '20' },
  ];

  /* Reps*/
  const handleClick = (e) => {
    e.preventDefault();
    // Your custom logic goes here
    console.log('Button clicked!');
  };
 
  useEffect(() => {
    document.title = 'pumpDB 3.0 - (sw) [2023]';
    /*
    setIsLoading(true);
    fetch(
      'https://seka.wittig-dresden.de/buying/',
      {
        method: 'GET',
        headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                  'Access-Control-Request-Headers': 'Authorization',
                   //'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded'
                 }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const buyings = [];

        for (const key in data) {
          const buying = {
            ID: key,
            ...data[key]
          };
          //console.log(buying);
          buyings.push(buying);
        }

        setIsLoading(false);
      })
      .catch(error => {console.log(error);  throw(error);} );*/
  }, []);

  if (isLoading) {
    return (
      <section>
        <p>Retrieving Data ... Delivery soon...</p>
      </section>
    );
  }

  return <><section>
              <h1>tpi</h1>
              <i>{debugTime}</i>
          </section>
          <section>
            <DeleteButton id="1554" onDelete={handleDeleteWorkout} caption="clean"/>
          </section>
          <section>
          <ButtonGroup>
            {radios.map((radio, idx) => (
              <ToggleButton size="sm"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                //variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                variant="outline-dark"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup> 
          </section>
          <br />
          <section>
          <Stack direction="horizontal" gap={3}>
            <Form.Control size="lg" type="text" className="me-auto" placeholder="add your weight" />
            <Button variant="secondary">Submit</Button>
            <div className="vr" />
            <Button variant="outline-danger">Reset</Button>
          </Stack>
          </section>
          <section>
          <Stack direction="horizontal" gap={3}>
            <Form >
              <Form.Control size="lg" type="text" className="me-auto" placeholder="add your weight" value={weight} onChange={handleWeightChange}/>
            </Form>  
            <Button variant="secondary" onClick={handleTPIpumpSubmit}>Submit</Button>
                <div className="vr" />
            <Button variant="outline-danger">Reset</Button>
            
          </Stack>
          </section>
          <section>
            <WorkoutPumpForm componentName="pumpTP" trainingID="506" displayMode="TP"></WorkoutPumpForm>
            <WorkoutPumpForm componentName="pumpFree" trainingID="506" displayMode="FREE"></WorkoutPumpForm>
          </section>
          <br />
          <section>
          <ExerciseList displayMode="SELECT"/>
          </section>
          <section>
            <Form.Select size="lg" aria-label="Default select example">
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </section>
          <br />
          <section>
          <InputGroup>
            <InputGroup.Radio aria-label="1" />
            <Form.Control aria-label="1" disabled readOnly value={1}/>
            <InputGroup.Radio aria-label="1" />
            <Form.Control aria-label="1" disabled readOnly value={2}/>
          </InputGroup>
          </section>
          <br />
          <section>
          <ButtonToolbar aria-label="Toolbar with button groups" >
            <ButtonGroup size="lg" className="me-2" aria-label="First group" vertical>
              <Button>1</Button> <Button>2</Button> <Button>3</Button>
              <Button>4</Button> <Button>5</Button>{' '}
            </ButtonGroup>
            
            <ButtonGroup size="lg" className="me-2" aria-label="Second group" vertical>
               <Button>6</Button> <Button>7</Button><Button>8</Button><Button>9</Button>
               <Button>10</Button> {' '}
            </ButtonGroup>
            <ButtonGroup size="lg" className="me-2" aria-label="Second group" vertical>
              <Button variant="outline-dark">11</Button> <Button variant="outline-dark">12</Button> 
              <Button variant="outline-dark">13</Button>
              <Button variant="outline-dark">14</Button> <Button variant="outline-dark">15</Button>{' '}
            </ButtonGroup>
          </ButtonToolbar>
          </section>
          <br />
          <section>
          <div
              aria-live="polite"
              aria-atomic="true"
              className="bg-dark position-relative"
              style={{ minHeight: '240px', overflow: 'scroll' }}
            >
              <ToastContainer
                className="p-3"
                position="top-start"
                style={{ zIndex: 1 }} 
              >
                <Toast>
                  <Toast.Header closeButton={false}>
                    <img
                      src="holder.js/20x20?text=%20"
                      className="rounded me-2"
                      alt=""
                    />
                    <strong className="me-auto">last repetition</strong>
                    <small>5 mins ago</small>
                  </Toast.Header>
                  <Toast.Body>Bankdrücken 9 x 80 Kg</Toast.Body>
                </Toast>
                <Toast>
                  <Toast.Header closeButton={false}>
                    <img
                      src="holder.js/20x20?text=%20"
                      className="rounded me-2"
                      alt=""
                    />
                    <strong className="me-auto">last repetition</strong>
                    <small>11 mins ago</small>
                  </Toast.Header>
                  <Toast.Body>Bankdrücken 10 x 80 Kg</Toast.Body>
                </Toast>
                <Toast>
                  <Toast.Header closeButton={false}>
                    <img
                      src="holder.js/20x20?text=%20"
                      className="rounded me-2"
                      alt=""
                    />
                    <strong className="me-auto">last repetition</strong>
                    <small>13 mins ago</small>
                  </Toast.Header>
                  <Toast.Body>Bankdrücken 10 x 80 Kg</Toast.Body>
                </Toast>
              </ToastContainer>
            </div>
          </section>
          <br />
          <section>
          <div>
            <Button onClick={handleClick}>Click me</Button>
          </div>
          </section>
          </>
}

export default ExercisesPage;