import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './ExampleCarouselImage';
import TrainingsplanCard from './TrainingsplanCard'
import classes from './TrainingsUserCard.module.css';
import {format,  formatDistance} from 'date-fns';
import UserAndPlanContext from '../../store/UserAndPlanContext';

function TrainingsplanCarousel(props) {
  const userAndPlanCtx = useContext(UserAndPlanContext);
  const trainingsplanList = props.trainingsplan;

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  

  function togglePlanStatusHandler(event){
    const selectedTrainingsplan = event.currentTarget.id;
    //Im Context speichern
    userAndPlanCtx.addPlan({
      id: selectedTrainingsplan,
    });
  }

  return (//wird nicht initialisiert!!
    <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
      {trainingsplanList.map((plan) => {return <Carousel.Item key={plan.id} interval="9999999">
                                    <ExampleCarouselImage width="400" height="300" fillBG="#bec3c9"/>
                                      <Carousel.Caption>
                                        <TrainingsplanCard planId={plan.id}>
                                        <div className={classes.headerContent}>
                                          <Link  id={plan.id} to='/training' onClick={(e) =>{togglePlanStatusHandler(e) }}>
                                            <h2>{plan.name}</h2>
                                          </Link>
                                        </div>
                                        <div className={classes.content}>
                                            [{plan.id}] - Version: {plan.version}
                                            <br />
                                        </div>
                                        <div className={classes.content}>
                                            <br />
                                            I: {formatDistance(new Date(plan.insert_date), new Date(), { addSuffix: true })}
                                            <br />
                                            U: {formatDistance(new Date(plan.update_date), new Date(), { addSuffix: true })}
                                        </div> 
                                        </TrainingsplanCard>
                                      </Carousel.Caption>
                                    </Carousel.Item>})}
    </Carousel>
  );
}

export default TrainingsplanCarousel
  ;