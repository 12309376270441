import React from 'react';
import Button from 'react-bootstrap/esm/Button';

function DeleteButton({ id, caption, onDelete }) {
  // Handler-Funktion zum Löschen des Datensatzes
  const handleDelete = (e) => {
    e.preventDefault();
    // Aufruf der onDelete-Funktion und Übergabe der ID als Parameter
    onDelete(id);
  };

  return (
    <Button variant="outline-danger" onClick={handleDelete} >{caption}</Button>

  );
}

export default DeleteButton;



