import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import React from 'react';

import './index.css';
import App from './App';

import { AuthContextProvider } from "./store/AuthContext";
import { UserAndPlanContextProvider } from "./store/UserAndPlanContext";


const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);


root.render( <React.StrictMode>
    <AuthContextProvider>
        <UserAndPlanContextProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </UserAndPlanContextProvider>
    </AuthContextProvider>
  </React.StrictMode>);


 