import {useEffect, useState, useRef} from 'react';
import React from 'react';
import {format,  formatDistance} from 'date-fns';

import LoadingSpinner from './LoadingSpinner';
import Table from 'react-bootstrap/Table';


const WorkoutExerciseRanking = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [workoutExcerciseRank, setWorkoutExcerciseRank] = useState([]);

    useEffect(() => {
        console.log("Query Workout Rank :"+props.rankRefresh);

          setIsLoading(true);
          fetch(
            process.env.REACT_APP_API_HOST+"/ranking/"+props.training,
            {
              method: 'GET',
              headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                        'Access-Control-Request-Headers': 'Authorization',
                        //'Content-Type': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
            })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              //bestehendes Array leeren und neu füllen
              workoutExcerciseRank.splice(0, workoutExcerciseRank.length);
              setWorkoutExcerciseRank(workoutExcerciseRank);
              console.log(workoutExcerciseRank.length);
      
              for (const key in data) {
                  const tmpWER = {
                    id: key,
                    ...data[key]
                  };

                  workoutExcerciseRank.push(tmpWER);
                }
                setWorkoutExcerciseRank(workoutExcerciseRank);  
                setIsLoading(false);    
                console.log("lese Rank neu aus " + workoutExcerciseRank.length); 
            })
            .catch(error => {console.log(error);  throw(error);} );
      }, [props.rankRefresh]);
    
      if (isLoading) {
        return (<LoadingSpinner />);
      }

    return (<section>
            {error && <div>{error}</div>}
            <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Exercise</th>
                  <th>Average</th>
                  <th>Pumped Weight</th>
                </tr>
              </thead>
              <tbody>
              {workoutExcerciseRank.map((wER) => {return <tr key={wER.extid}>
                                                            <td>{wER.rank}</td>
                                                            <td>{wER.name}</td>
                                                            <td>{wER.average}</td>
                                                            <td>{wER.weight}</td>
                                                          </tr>})}
                </tbody>
              </Table> 
            </div></section>)

}
  
export default WorkoutExerciseRanking;