import React, { useState, useContext, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './ExampleCarouselImage';
import WorkoutTPICard from './WorkoutTPICard'
import classes from './WorkoutTPICarousel.module.css';

import UserAndPlanContext from '../../store/UserAndPlanContext';
import LoadingSpinner from '../widgets/LoadingSpinner';

function WorkoutTPICarousel(props) {
  const userAndPlanCtx = useContext(UserAndPlanContext);
  const tpiList = props.tpi;
  const liftsToGo = tpiList.length
    
  const [isLoading, setIsLoading] = useState(false);

  const getTPIByIndex = (index) => {
    return tpiList[index];
  };

  const [index, setIndex] = useState(0);  
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    
    // nach oben geben
    props.onChangeWTPI(getTPIByIndex(selectedIndex));
  }; 

 
  useEffect(()=>{

    setIsLoading(true);
    props.onChangeWTPI(getTPIByIndex(index));
    setIsLoading(false);
    
},[props.tpi]); 

if (isLoading) {
      return (<LoadingSpinner />);
}

  return (//wird nicht initialisiert!!
    <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
      {tpiList.map((tpi) => {return <Carousel.Item key={tpi.id} interval="9999999">
                                    <ExampleCarouselImage  width="400" height="100" fillBG="#bec3c9"/>
                                      <Carousel.Caption>
                                        <WorkoutTPICard tpi={tpi.id}>
                                          <div className={classes.tpiExerciseName}><small><b>[{tpi.id}] - {tpi.name} | {tpi.repetition} x {tpi.weight} kg </b></small></div>
                                        </WorkoutTPICard>
                                        <small className={classes.tpiFooter}>still {liftsToGo} lifts to go</small>
                                      </Carousel.Caption>
                                    </Carousel.Item>})}
    </Carousel>
  );
}

export default WorkoutTPICarousel;