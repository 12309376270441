import classes from './TrainingsUserCard.module.css';
import React from 'react';
import { useContext } from 'react';
import UserAndPlanContext from '../../store/UserAndPlanContext';

function TrainingsUserCard(props) {
    const userAndPlanCtx = useContext(UserAndPlanContext);
    const currentUserIdToRetrieve = userAndPlanCtx.user;

    return <div className={props.userId == currentUserIdToRetrieve.id ? classes.activeCard : classes.card}>{props.children}</div>
}

export default TrainingsUserCard;