import { createContext, useState } from 'react';
import React from 'react';

const UserAndPlanContext = createContext({
  user: '',
  plan: '',
  addUser: (selectedUser) => {},
  removeUser: (userId) => {},
  addPlan: (selectedPlan) => {},
  removePlan: (planId) => {}
});

export function UserAndPlanContextProvider(props) {
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');

  function addUserHandler(selectedUser) {
    setSelectedUser(selectedUser);
  }

  function addPlanHandler(selectedPlan) {
    setSelectedPlan(selectedPlan);
  }

  function removeUserHandler() {
    setSelectedUser('');
  }

  function removePlanHandler() {
    setSelectedPlan('');
  }


  const context = {
    user: selectedUser,
    plan: selectedPlan,
    addUser: addUserHandler,
    removeUser: removeUserHandler,
    addPlan: addPlanHandler,
    removePlan: removePlanHandler
  };

  return (
    <UserAndPlanContext.Provider value={context}>
      {props.children}
    </UserAndPlanContext.Provider>
  );
}

export default UserAndPlanContext;