import classes from './WorkoutTPICard.module.css';
import React from 'react';
import { useContext } from 'react';
import UserAndPlanContext from '../../store/UserAndPlanContext';

function WorkoutTPICard(props) {
    const userAndPlanCtx = useContext(UserAndPlanContext);
    const currentUserIdToRetrieve = userAndPlanCtx.user;

    return <div className={classes.activeCard}>{props.children}</div>
}

export default WorkoutTPICard;