import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './ExampleCarouselImage';
import TrainingsUserCard from './TrainingsUserCard'
import classes from './TrainingsUserCard.module.css';
import {format,  formatDistance} from 'date-fns';
import UserAndPlanContext from '../../store/UserAndPlanContext';


function TrainingsUserCarousel(props) {
  const userAndPlanCtx = useContext(UserAndPlanContext);
  const userList = props.user;

  const [index, setIndex] = useState(0);  
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  

  function toggleUserStatusHandler(event){
    const selectedUser = event.currentTarget.id;
    //Im Context speichern
    userAndPlanCtx.addUser({
      id: selectedUser,
    });

    
  }

  return (//wird nicht initialisiert!!
    <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
      {userList.map((user) => {return <Carousel.Item key={user.id} interval="9999999">
                                    <ExampleCarouselImage  width="400" height="200" fillBG="#bec3c9"/>
                                      <Carousel.Caption>
                                        <TrainingsUserCard userId={user.id}>
                                        <div className={classes.headerContent}>
                                            <Link  id={user.id} to='/training' onClick={(e) =>{toggleUserStatusHandler(e) }}>
                                              <h2>{user.username} - [{user.id}]</h2>
                                            </Link>
                                        </div>
                                        <div className={classes.content}>
                                            {user.description}
                                        </div>
                                        <div className={classes.content}>
                                            <br />
                                            {formatDistance(new Date(user.created), new Date(), { addSuffix: true })}
                                        </div> 
                                        </TrainingsUserCard>
                                      </Carousel.Caption>
                                    </Carousel.Item>})}
    </Carousel>
  );
}

export default TrainingsUserCarousel;