import {useEffect, useState, useRef} from 'react';
import React from 'react';
import WorkoutTPICarousel from '../ui/WorkoutTPICarousel';
import LoadingSpinner from './LoadingSpinner';

const WorkoutTPIComponent = (props) => {
    
    const [activeWorkoutTPI, setActiveWorkoutTPI] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    function changeWTPIHandler(selectedWTPIData){
      // nach oben geben
      props.onChangeWTPI(selectedWTPIData);
    }

    useEffect(()=>{

      if(props.addedWorkout.id == null)
      {
        setIsLoading(true);
        fetch(
            process.env.REACT_APP_API_HOST+"/training/"+props.training+"/plan/"+props.plan,//'/trainingsplan', //training/user
            {
              method: 'GET',
              headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                        'Access-Control-Request-Headers': 'Authorization',
                        //'Content-Type': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
            })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              const workoutTPI = [];
              setActiveWorkoutTPI(workoutTPI);

              for (const key in data) {
                const tmpWTPI = {
                  id: key,
                  ...data[key]
                };

                workoutTPI.push(tmpWTPI);
              }
              setActiveWorkoutTPI(workoutTPI);  
              setIsLoading(false);               
            }).catch(error => {console.log(error);  throw(error);} 
            );
        }
        else{
            // Workout aus WTPI rausnehmen
            console.log("Diff:",activeWorkoutTPI.filter(item => item.id !== parseInt(props.addedWorkout.tp_item)));
            setActiveWorkoutTPI(activeWorkoutTPI.filter(item => item.id !== parseInt(props.addedWorkout.tp_item)));
        }

  },[props.addedWorkout]); 

  if (isLoading) {
    return (<LoadingSpinner />);
  }
  
  return (<WorkoutTPICarousel tpi={activeWorkoutTPI} onChangeWTPI={changeWTPIHandler}/>)
   }

  export default WorkoutTPIComponent;