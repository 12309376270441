import { Link } from 'react-router-dom';
import React from 'react';
import AppUser from "../../pages/AppUser";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import  { useState } from 'react';

function MainNavigation() {
  const [expanded, setExpanded] = useState(false);
  
  const handleNavItemClick = () => {
    // Schließe das Navbar.Collapse-Element, indem der Zustand auf false gesetzt wird
    setExpanded(false);
  };
  
  return (
    <header>
      <Navbar expanded={expanded} collapseOnSelect expand="lg"  bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>pump 3.0</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-link" onClick={handleNavItemClick} to='/'>^</Link>
              <Link className="nav-link" onClick={handleNavItemClick} to='/me'>User</Link>
              <Link className="nav-link" onClick={handleNavItemClick} to='/exercises'>Übungen</Link>
              <Link className="nav-link" onClick={handleNavItemClick} to='/workout'>Workout</Link>
              <Link className="nav-link" onClick={handleNavItemClick} to='/stats'>Live Stats</Link>
              <Link className="nav-link" onClick={handleNavItemClick} to='/dev'>[Dev]</Link>
              <Link className="nav-link" onClick={handleNavItemClick} to='/out'><AppUser content="X"/></Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>



  );
}

export default MainNavigation;