import {useEffect, useState, useRef} from 'react';
import React from 'react';
//Unsicher ob ich überhaupt diesen State brauche und nicht gleich auf den Storage gehe
const AppUser = (props) => {
    const [appUser, setAppUser] = useState([]); useEffect(()=>{
      const uuid = JSON.parse(localStorage.getItem("user")) || null;
      const storedAppUser = JSON.parse(localStorage.getItem("appUser")) || '...';
      if(storedAppUser === '...' && uuid != null)
      {
          fetch(
              process.env.REACT_APP_API_HOST+'/uuid/'+uuid.uid,
              {
                method: 'GET',
                headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                          'Access-Control-Request-Headers': 'Authorization',
                          //'Content-Type': 'application/json',
                          'Content-Type': 'application/x-www-form-urlencoded'
                        }
              })
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                const storableUserData = {
                  ID : data.ID,
                  USER_NAME : data.USER_NAME,
                };                                
                setAppUser(storableUserData);
                localStorage.setItem("appUser", JSON.stringify(storableUserData));
                console.log(storableUserData);
              });
      }
      setAppUser(storedAppUser);
    },[]); 
    return (<span key={appUser.ID}>{props.content} ( {appUser.USER_NAME} )</span>);
     }

    export default AppUser;