import {useEffect, useState, useRef} from 'react';
import React from 'react';
import TrainingsUserItem from './TrainingsUserItem'
import classes from './TrainingsUserList.module.css';
import TrainingsUserCarousel from '../ui/TrainingsUserCarousel';
import LoadingSpinner from './LoadingSpinner';

const TrainingsUserList = (props) => {
    
    const [activeTrainingsUser, setActiveTrainingsUser] = useState([]);
    const [userName, setUserName] = useState([]); 

    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(()=>{

        setIsLoading(true);
        fetch(
            process.env.REACT_APP_API_HOST+'/trainingsuser', //training/user
            {
              method: 'GET',
              headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                        'Access-Control-Request-Headers': 'Authorization',
                        //'Content-Type': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
            })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              const trainingsUser = [];

              for (const key in data) {
                const tmpUser = {
                  id: key,
                  ...data[key]
                };
                
                trainingsUser.push(tmpUser);
              }
              setActiveTrainingsUser(trainingsUser);
              setIsLoading(false);                 
            });
  },[]); 

  if (isLoading) {
    return (<LoadingSpinner />);
  }
  
  return (<TrainingsUserCarousel user={activeTrainingsUser} />)

  /*return (<div className={classes.box}>
          {activeTrainingsUser.map((user) => {return <TrainingsUserItem userName={user.username} userId={user.id} />})}
          </div>)
  */
   }

  export default TrainingsUserList;