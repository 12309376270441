import Form from 'react-bootstrap/Form';
import { useState, useEffect, useContext } from 'react';
import LoadingSpinner from '../widgets/LoadingSpinner';

const ExerciseList = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [exerciseList, setExerciseList] = useState([]);
    const [selectedValue,setSelectedValue]=useState('')

    const displayMode = props.displayMode;

    const getExerciseByIndex = (index) => {
      return exerciseList[index];
    };

    function handleExerciseChange(event){
      setSelectedValue(event.target.value);
      console.log("ValueSELECT:",event.target.value);
      console.log("Exercise:",getExerciseByIndex(event.target.value));

      //Daten nach oben geben
      props.onExerciseChange(getExerciseByIndex(event.target.value));   
    }

    const emptyExercise = 
    {
      ext_id: '-----',
      name: 'choose your exercise',
      def_repetitions: 10,
      def_weight: 50
    };

    useEffect(()=>{

        setIsLoading(true);
        fetch(
            process.env.REACT_APP_API_HOST+"/exercise",//'/trainingsplan', //training/user
            {
              method: 'GET',
              headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                        'Access-Control-Request-Headers': 'Authorization',
                        //'Content-Type': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
            })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              const workoutEL = [];
              setExerciseList(workoutEL);

              workoutEL.push(emptyExercise);

              for (const key in data) {
                const tmpEL = {
                  id: key,
                  ...data[key]
                };
                
                workoutEL.push(tmpEL);
              }
              setExerciseList(workoutEL);  
              setIsLoading(false);              
            }).catch(error => {console.log(error);  throw(error);} ); 
  },[]); 



  if (isLoading) {
    return (<LoadingSpinner />);
  }
  
  return (  <>
            {displayMode=='SELECT' ?
            <Form.Select size="md" value={selectedValue} onChange={handleExerciseChange}>
                    {exerciseList.map((exercise,index) => (<option key={index} value={index}>{exercise.name} ({exercise.ext_id})</option>))}
            </Form.Select> 
            : <p>weiter Visualisierung noch offen</p>
            }
            </>
  );
}

export default ExerciseList;