import { useState, useEffect, useContext } from 'react';
import LoadingSpinner from '../widgets/LoadingSpinner';
import CardWithHeaderAndFooter from './CardWithHeaderAndFooter';
import {format,  formatDistance,parse} from 'date-fns';
import Button from 'react-bootstrap/esm/Button';

const Trainingspanel = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [currentTraining, setCurrentTraining] = useState(props.training);
  const [currentTP, setCurrentTP] = useState(props.tp);
  const [formattedDate, setFormattedDate ] = useState(null);
   
  const handleStopTraining = () => {
    // Update senden und zur Finish-Seite gehen irgendwie später
    const currentDate = new Date();
    const formattedDate = format(currentDate, 'yyyy-MM-dd HH:mm:ss');

    const stopTraining =
    {
      finish: formattedDate,
      id: currentTraining.id
    }; 

    fetch(
      process.env.REACT_APP_API_HOST+'/training/'+stopTraining.id,
      {
        method: 'PATCH',
        body: JSON.stringify(stopTraining),
        headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                  'Access-Control-Request-Headers': 'Authorization',
                   //'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded'
                 }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Return aus Stop Training: " + data.message + "Rows: " + data.rows);
      })
      .catch(error => {console.log(error);  throw(error);} );
  };   

  if (isLoading) {
    return (<LoadingSpinner />);
  }
  
  return (  <>
            <CardWithHeaderAndFooter header={"Training Nummer: " + currentTraining.id}
                                     content={" mit User " + currentTraining.user + (currentTP.id ? " und Plan " + currentTP.id : "")} 
                                     title={"Gestartet " + currentTraining.start}
                                     footer={formattedDate}/>
           <p><Button variant="outline-danger" onClick={handleStopTraining} >Stop</Button></p>
           </>
  );
}

export default Trainingspanel;